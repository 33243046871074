import ParameterInput from './ParameterInput.tsx';
import { Parameter } from './Types.ts';

interface ParameterRowProps {
    param: Parameter;
    selectedValue: string;
    onParameterSelection: (parameter: Parameter, selectedValue: string) => void;
    index: number;
  }
  
  export default function ParameterRow({ param, selectedValue, onParameterSelection, index }: ParameterRowProps) {
    const rowBackgroundColor = index % 2 === 0 ? 'bg-slate-100' : 'bg-slate-200';
  
    return (
      <tr class={`${rowBackgroundColor}`}>
        <td class="py-1 px-4 border-b align-top font-semibold">
          <div>{param.name}</div>
          <div class="text-xs text-slate-600">{param.schema?.type || 'N/A'}</div>
          <div class="text-xs text-slate-600">({param.in})</div>
        </td>
  
        <td class="py-1 px-4 border-b align-top">
          <div class="mb-2">{param.description || 'No description'}</div>
          {/* Übergabe des index an ParameterInput */}
          <ParameterInput param={param} selectedValue={selectedValue} onParameterSelection={onParameterSelection} index={index} />
        </td>
      </tr>
    );
  }
